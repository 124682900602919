.navigation {
  padding: 26px 0;
  font-size: 14px;
}
.navigation span {
  cursor: pointer;
  padding: 0 8px;
}
.navigation span:first-child{
  padding-left:  0;
}

.navigation .active {
  color: #0080FF;
}