/* .wrap {
  height: 100vh ;
}
.aside {
  background: #358CFF;
}
.right {
  background: #F7FAFC;
  display: flex;
  align-items: center;
  justify-content: center;
} */

.login-container {
  display: flex;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

img.aside {
  height: 100%;
  width: auto;
}

img.aside-logo {
  position: absolute;
  top: 72px;
  width: 149px;
  z-index: 100;
  margin-left: 50%;
  left: -74.5px;
}

.form-wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

aside {
  position: relative;
}

.aside-icon {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
}