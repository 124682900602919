.risk-info-wrap {
  display: flex;
  margin-top: 20px;
}
.risk-info-wrap .info-item {
  width: 597px;
}

.risk-info-wrap .info-item + .info-item {
  margin-left: 20px;
}