.container {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
}
.title {
  font-size: 20px;
  padding-top: 20px;
  margin: 0;
}
.subtitle {
  font-size: 14px;
  padding-top: 10px;
  margin: 0;
}
.content {
  font-size: 14px;
  padding-top: 10px;
}

.win {
  width: 500px;
  height: 200px;
  background: #ffffff;
  text-align: center;
  border-radius: 6px;
}

.buttonwrap button {
  /* margin-top: 10px; */
  margin: 10px
}