.account-info-container {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
}
.account-info-container .info-item {
  width: 290px;
  /* height: 100px; */
  background: #FFFFFF;
  border: 0.5px solid #E8E8ED;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  padding: 29px 0 25px 24px;
  display: flex;
}
.account-info-container .info-item .icon {
  width: 46px;
  height: 46px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 16px;
  flex-shrink:0;

}
.account-info-container .info-question-icon {
  width: 18px;
  height: 18px;
  background-color: #fff;
  display: inline-block;
  margin-left: 5px;
}
.account-info-container .info-question-icon img {
  width: 100%;
  height: auto;
  margin-top: -2px;
}

.account-info-container .info p {
  margin-bottom: 0;
  margin-top: 4px;
}
.account-info-container .info .describe {
  font-weight: 700;
  font-size: 12px;
  line-height: 15px;
  color: #7A7A9D;
}
.account-info-container .info .money {
  font-weight: 600;
  font-size: 18px;
  color: #16192C;
  line-height: 20px;
  display: flex;
  align-items: center;
}
.account-info-container .info .money span:last-child {
  font-weight: 500;
  font-size: 12px;
}
.account-info-container .info .money span:nth-child(2) {
  padding-left: 2px;
}

.account-info-container .info .balance-money {
  flex-direction: column;
  align-items: flex-start;
}
.account-info-container .info .balance-money span {
  padding: 0;
}