.alert-page-container nav.nav-list li {
  list-style: none;
  display: inline-block;
  width: 128px;
  text-align: center;
  height: 57px;
  line-height: 57px;
  cursor: pointer;
  transition: .5s ease;
  border-bottom: 2px solid #fff;
  font-size: 14px;
}
.alert-page-container nav.nav-list li:hover,nav.nav-list li.active {
  border-color: #4C6FFF;
  color: #4C6FFF;
}

.alert-page-container .list-container {
  background: #FFFFFF;
  border: 0.5px solid #E8E8ED;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  padding: 0 27px;
  position: relative;
}
.alert-page-container nav.nav-list {
  height: 57px;
  margin-left: 24px;
}

.alert-page-container .paging-wrap {
  padding-top: 16px;
  padding-bottom: 16px;
}

.alert-page-container .table-wrap {
  /* min-height: 250px; */
  height: 261px;
}

.alert-page-container .address-search {
  width: 216px;
  height: 40px;
}
.alert-page-container .address-search input {
  width: 180px;
  height: 100%;
  border: none;
  font-size: 14px;
  padding: 12px 10px;
  outline: none;
}

.alert-page-container .address-search {
  background: #FFFFFF;
  border: 1px solid #DBDDE0;
  border-radius: 6px;
  width: 216px;
  height: 40px;
  position: absolute;
  right: 0;
  top: -58px;
}

.alert-page-container .address-search img {
  margin-left: 16px;
  width: 16px;
  height: 16px;
}