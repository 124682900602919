
.alert-enter {
  opacity: 0;
  transform: translateY(-40px);
}
.alert-enter-active {
  opacity: 1;
  transition: all 200ms ease-out;
  transform: translateY(0);
}
.alert-exit {
  opacity: 1;
}
.alert-exit-active {
  opacity: 0;
  transition: all 200ms ease-in;
  transform: translateY(-40px);
}