.container {
  display: inline-block;
  padding-left: 24px;
  margin-bottom: 20px;
}
.container img {
  width: 20px;
  height: 20px;
  margin-top: -2px;
}
.container span {
  font-size: 14px;
  line-height: 20px;
  color: #343B41;
  padding-left: 5px;
}