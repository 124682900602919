@import url(https://fonts.googleapis.com/css?family=Montserrat:400,500,80);
* {
  font-family: 'Montserrat', sans-serif;
}

.body-container {
  width: 1218px;
  margin: auto;
  margin-top: 56px;
  padding-bottom: 20px;
}

.normal-click {
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

.App {
  background-color: #f5f9fb;
}

.button {
  width: 120px;
  line-height: 38px;
  border: none;
  border-radius: 6px;
  font-size: 14px;
}

.cancel-button {
  background: #ffffff;
  border: 1px solid #ECEEF0;
  color: #606E7B;
}

.success-button {
  background: #0080FF;
  border: 1px solid #0080FF;
  color: #ffffff;
  cursor: pointer;
  display: inline-block;
  text-align: center;
}

.success-button.loading {
  position: relative;
  text-align: right;
  padding-right: 25px;
  background: #9ac9f7;
  border-color: transparent;
}

.success-button.loading .loadicon {
  position: absolute;
  top: 0;
  left: 10px;
}

.clickable {
  cursor: pointer;
}

/* 
*::-webkit-scrollbar {
  display: none
} */

input[type=number]::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}


.container-item {
  position: relative;
}

.container-item .container-loadicon {
  position: absolute;
  right: 35px;
  top: 15px;
}