.PopoverContainer {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0px;
  text-align: center;
  cursor: pointer;

}

.PopoverItem {
  display: flex;
  align-items: center;
  padding: 12px;
  color: rgba(96, 110, 123, 1);
  cursor: pointer;
  .PopoverItemImge {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    display: block;
  }
}

.PopoverItem:hover {
  cursor: pointer;
  color: rgba(0, 128, 255, 1);

  .PopoverItemImge,
  .PopoverItemImge svg path {
    fill: rgba(0, 128, 255, 1);
  }
}