.topic-info {
  background: #FFFFFF;
  border: 0.5px solid #E8E8ED;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  padding: 26px 18px 40px;
  display: flex;
  justify-content: space-between;
}

.topic-info p {
  margin-bottom: 0;
}

.topic-info .title {
  font-weight: 700;
  font-size: 18px;
  line-height: 16px;
  letter-spacing: 0.12px;
  color: #425466;
  margin-bottom: 21px;
  position: relative;
}

.topic-info .title .topic-info-switch {
  position: absolute;
  right: 27px;
  top: 0;
}


.topic-info .info-body {
  display: flex;
  align-items: flex-start;
}

.topic-info .info-body .info {
  margin-left: 15px;
}

.topic-info .name {
  font-weight: 500;
  font-size: 16px;
  line-height: 14px;
  color: #425466;
  margin: 5px 0 8px;
}

.topic-info .belong-info {
  display: flex;
  align-items: flex-start;
  margin-bottom: 12px;
  /* margin-top: 12px; */
}

.body-img {
  width: 48px;
  height: 48px;
}

.belong-info .belong-text {
  font-weight: 400;
  font-size: 14px;
  line-height: 14px;
  color: #425466;
  margin-right: 24px;
  margin-top: 5px;
}

.belong-info .belong-text a {
  text-decoration: none;
}

.belong-info .belong-text span {
  color: #4C6FFF;
  cursor: pointer;
}

.belong-info .tags {
  max-width: 800px;
}

.belong-info .tags span {
  padding: 2px 10px;
  text-align: center;
  background-color: #F9EEDC;
  color: #856320;
  border-radius: 20px;
  min-width: 40px;
  line-height: 14px;
  display: inline-block;
  font-size: 10px;
  margin-top: -5px;
  margin-right: 10px;
}

.info-body .detail-info,
.info-body .address-count {
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  letter-spacing: 0.12px;
  color: rgba(66, 84, 102, 0.5);
  word-break: break-word;
  max-width: 850px;
}


.topic-info .active-time p {
  margin: 0;
  font-size: 12px;
  line-height: 20px;
  text-align: right;
  color: rgba(66, 84, 102, 0.5);
}

.topic-info .active-time {
  position: absolute;
  bottom: 0;
  width: 300px;
  right: 0;
}

.topic-info .right {
  position: relative;
  margin-right: 30px;
}

.topic-info .notice {
  width: 32px;
  height: 32px;
  cursor: pointer;
}

.topic-info .notice img {
  width: 100%;
  height: 100%;
}