.container {
  /* padding-left: 24px;
  padding-right: 24px; */
  margin-bottom: 16px;
  position: relative
}
.title {
  font-size: 12px;
  line-height: 16px;
  color: #646F79;
  margin-bottom: 6px;
}
.container input {
  width: 360px;
  height: 45px;
  border: 1px solid #DBDDE0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 14px;
  padding: 0 10px;
  float: left;
}


.select {
  padding: 14px 16px;
  border: 1px solid #DBDDE0;
  border-radius: 6px;
  font-size: 14px;
  line-height: 14px;
  cursor: pointer;
  background: #ffffff;
  display: inline-block;
  margin-left: 15px;
  clear: both;
}
.selectIcon {
  margin-left: 10px;
}
.content {
  width: 53px;
  display: inline-block;
}

.option {
  background: #fff;
  border: 1px solid #DBDDE0;
  margin-top: 0px;
  /* padding: 6px 16px; */
  border-radius: 0 0 6px 6px;
  font-size: 14px;
  position: absolute;
  top: 66px;
  left: 400px;
  width: 107px;
  z-index: 1;
}
.option .content {
  padding: 10px 16px;
  cursor: pointer;
  width: 100%;
}
.option .content:hover {
  background: #f9f6f6;
}

/* .container .option {
  width: 53px;
  position: absolute;
} */