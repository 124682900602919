.title {
  color: #ffffff;
  font-size: 46px;
  line-height: 46px;
  text-align: center;
  padding-top: 157px;
  margin-bottom: 72px;
  font-weight: 300;
}

.container {
  background: url("@/assets/searchBanner.png") no-repeat;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  
  height: 440px;
  margin-top: 56px;
}

.search-icons {
  width: 835px;
  display: block;
  height: auto;
  margin: 60px auto 0;
}