.alert-detail-title {
  color: #425466;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 22px;
  /* 88.889% */
  letter-spacing: 0.12px;
  display: flex;
  padding: 10px 16px 10px 0;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  width: 100%;
  /* height: 52px; */
}

.alert-detail-top-container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.alert-detail-top-left-main {
  display: flex;
  width: 600px;
  height: 222px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #FFF;

  /* dropdown/shadow opened */
  box-shadow: 0px 0px 8px 0px rgba(37, 40, 43, 0.12);
}

.alert-detail-top-right-main {
  display: flex;
  width: 600px;
  height: 222px;
  padding: 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  flex-shrink: 0;
  border-radius: 8px;
  background: #FFF;

  /* dropdown/shadow opened */
  box-shadow: 0px 0px 8px 0px rgba(37, 40, 43, 0.12);
}

.mb24 {
  margin-bottom: 24px;
}

.mb14 {
  margin-bottom: 14px;
}

.mb18 {
  margin-bottom: 18px;
}

.alert-detail-top-title {
  color: var(--black-80, #343B41);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 16px;
  /* 100% */
  letter-spacing: 0.12px;
}

.alert-detail-top-item {
  display: flex;
  align-items: center;
  color: #425466;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.2px;
}

.alert-detail-top-item-title {
  color: #425466;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.2px;
  padding-right: 10px;
}

.alert-detail-top-item-content {
  color: #425466;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
  /* 133.333% */
  letter-spacing: 0.2px;
}
.alert-detail-middle-content{
  padding: 16px 0;
}
.alert-detail-list-padding{
    padding: 30px 27px;
}