.dashboard-container {
  background: #FFFFFF;
  border: 0.5px solid #E8E8ED;
  box-shadow: 0px 4px 3px 2px rgba(0, 0, 0, 0.02);
  border-radius: 4px;
  width: 290px;
  height: 150px;
}

:root {
  --arcRadius: 75px;
  --rectWidth: calc(var(--arcRadius) * 2);
  --trackWidth: 20px;
  --roundWidth: 20px;
}
.arc-wrapper {
  position: relative;
  margin: 0 auto;
  width: var(--rectWidth);
  height: var(--arcRadius);
  overflow: hidden;
  /* background-color: pink; */
  background: #fff;
  margin-top: 20px;
}
.track-arc {
  width: 100%;
  height: var(--rectWidth);
  box-sizing: border-box;
  border-radius: 50%;
  border: var(--trackWidth) solid #ECEEF0;
}
.round-box {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  transform-origin: 50% 100%;
  /* transform: rotate(-50deg); */
  z-index: 20;
}
.round {
  width: 100%;
  height: var(--rectWidth);
  box-sizing: border-box;
  border-radius: 50%;
  border: var(--roundWidth) solid transparent;
}

.score {
  text-align: center;
  font-weight: 700;
  font-size: 30px;
  line-height: 20px;
  letter-spacing: 0.2px;
  position: relative;
  margin: -20px 0 0;
}
.level {
  text-align: center;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  color: #425466;
}
.detail-text {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  margin-bottom: 20px;
}

.detail-text div {
  width: 8px;
  height: 8px;
  border-radius: 5px;
}

.detail-text p {
  margin: 0 0 0 6px;
}