.paging {
  display: flex;
  align-items: center;
  justify-content: right;
}
.result-text {
  font-size: 14px;
  line-height: 20px;
  color: #646F79;
  margin: 0;
}
.divider {
  margin: 0 16px;
  width: 1px;
  height: 32px;
  background: #ECEEF0;
}
.paging-button {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  min-width: 32px;
  padding: 0 5px;
  height: 32px;
  border: 1px solid #ECEEF0;
  border-radius: 4px;

  -moz-user-select:none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}
.paging-button + .paging-button {
  margin-left: 8px;
}
.paging-button.normal {
  border: none;
  cursor: default;
  align-items: flex-end;
  padding-bottom: 8px;
}
.paging-button {
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: #0A0A0A;
  transition: background-color .5s ease, color .5s ease, border .5s ease;
}
.paging-button:hover {
  color: #0080FF;
  border-color: #0080FF;
}
.paging-button.active {
  background: #0080FF;
  border-color: #0080FF;
  color: #ffffff;
}